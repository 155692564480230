/* eslint-disable quotes */
import bp from './breakpoints';

const mq = {
  mobile: `${bp.mobile}px`,
  tabletSmall: `${bp.tabletSmall}px`,
  tablet: `${bp.tablet}px`,
  desktop: `${bp.desktop}px`,
};

export const bz = `
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  `;

export const jcc = `
  display: flex;
  justify-content: center;
  align-items: center;
  `;

export const colors = {
  dark: '#0B070E',
  light: '#fafafa',
  purple: '#9135AD',
  red: '#7F141E',
  blue: '#134357',
  darkPurple: '#2B2034',
  orange: '#ec8351',
  darkText: '#2b2e34',
};

export const font = {
  display: `
    font-family: 'DK Liquid Embrace', sans-serif;
    font-size: 8rem;
    font-weight: normal;
    font-style: normal;
    line-height: 120%;
    @media screen and (max-width: ${mq.tabletSmall}) {
      font-size: 6rem;
  }
  `,
  displaySmallTitle: `
    font-family: 'DK Liquid Embrace', sans-serif;
    font-size: 6rem;
    font-weight: normal;
    font-style: normal;
    line-height: 120%;
    @media screen and (max-width: ${mq.tabletSmall}) {
      font-size: 4rem;
  }
  `,
  displaySmall: `
    font-family: 'DK Liquid Embrace', sans-serif;
    font-size: 4rem;
    font-weight: normal;
    font-style: normal;
    line-height: 120%;
  `,
  sectionHeading: `
        font-family: 'Karla', sans-serif;
        font-size: 4.8rem;
        font-weight: bold;
        line-height: 110%;
        text-transform: uppercase;
        @media screen and (max-width: ${mq.tabletSmall}) {
            font-size: 3.6rem;
        }
    `,
  h1cat: `
        font-family: 'Karla', sans-serif;
        font-size: 8rem;
        font-weight: bold;
        line-height: 110%;
        text-transform: uppercase;
        @media screen and (max-width: ${mq.tabletSmall}) {
            font-size: 5.2rem;
        }
    `,
  h1blog: `
        font-family: 'Karla', sans-serif;
        font-size: 4rem;
        font-weight: bold;
        line-height: 110%;
        text-transform: none;
        text-shadow: -.1rem -.1rem ${colors.orange};
        @media screen and (max-width: ${mq.tabletSmall}) {
            font-size: 2.8rem;
        }
    `,
  h2blog: `
        font-family: 'Karla', sans-serif;
        font-size: 2.8rem;
        font-weight: bold;
        line-height: 110%;
        text-transform: none;
        text-shadow: -.1rem -.1rem ${colors.orange};
        @media screen and (max-width: ${mq.tabletSmall}) {
            font-size: 2.6rem;
        }
    `,
  h3blog: `
        font-family: 'Karla', sans-serif;
        font-size: 2.6rem;
        font-weight: bold;
        line-height: 110%;
        text-transform: none;
        text-shadow: -.1rem -.1rem ${colors.orange};
        @media screen and (max-width: ${mq.tabletSmall}) {
            font-size: 2.4rem;
        }
    `,
  h4blog: `
        font-family: 'Karla', sans-serif;
        font-size: 2.4rem;
        font-weight: bold;
        line-height: 110%;
        text-transform: none;
        text-shadow: -.1rem -.1rem ${colors.orange};
        @media screen and (max-width: ${mq.tabletSmall}) {
            font-size: 2rem;
        }
    `,
  h1: `
        font-family: 'Karla', sans-serif;
        font-size: 4.8rem;
        font-weight: bold;
        line-height: 110%;
        text-transform: uppercase;
        @media screen and (max-width: ${mq.tabletSmall}) {
            font-size: 3.6rem;
        }
    `,
  h2: `
        font-family: 'Karla', sans-serif;
        font-size: 3.5rem;
        font-weight: bold;
        line-height: 110%;
        text-transform: uppercase;
        @media screen and (max-width: ${mq.tabletSmall}) {
            font-size: 2.4rem;
        }
    `,
  h4: `
        font-family: 'Karla', sans-serif;
        font-size: 2.2rem;
        font-weight: bold;
        line-height: 120%;
    `,
  p: `
        font-family: 'Open Sans', sans-serif;
        font-size: 1.6rem;
        font-weight: normal;
        line-height: 160%;
    `,
  blogBody: `
        font-family: 'Karla', sans-serif;
        font-size: 1.6rem;
        line-height: 160%;
    `,
  menuLink: `
        font-family: 'Montserrat', sans-serif;
        font-size: 1.6rem;
        font-weight: bold;
        line-height: 120%;
        text-transform: uppercase;
        text-decoration: none;
        background: transparent;
        border: none;
    `,
  button: `
    font-family: 'Karla', sans-serif;
    font-size: 2.2rem;
    line-height: 110%;
    text-transform: uppercase;
    height: 5.2rem;
    border-radius: .4rem;
    background: ${colors.blue};
    color: white;
    border: none;
    padding: 0 2.4rem;
    `,
  buttonInverse: `
    font-family: 'Karla', sans-serif;
    font-size: 2.2rem;
    line-height: 110%;
    text-transform: uppercase;
    height: 5.2rem;
    border-radius: .4rem;
    background: transparent;
    border: .2rem solid ${colors.blue};
    color: white;
    padding: 0 2.4rem;
    `,
};

export const z = {
  headerMobile: `z-index: 80000;`,
  headerMobileSub: `z-index: 85000;`,
  header: `z-index: 90000;`,
  modalBackground: `z-index: 100000;`,
  modal: `z-index: 200000;`,
};

export const duration = 500;
