import PropTypes from 'prop-types';

export const providerTypes = {
  children: PropTypes.node.isRequired,
};

export const seoTypes = {
  meta: PropTypes.object.isRequired,
};

export const blogPostTypes = {
  data: PropTypes.object.isRequired,
};

export const layoutTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
};

export const headerTypes = {
  location: PropTypes.object.isRequired,
  scrolled: PropTypes.bool.isRequired,
  scrollPosition: PropTypes.number,
};

export const homeHeroTypes = {
  heroTitle: PropTypes.string.isRequired,
  heroImage: PropTypes.object.isRequired,
  heroSubtitle: PropTypes.string.isRequired,
  heroButtonText: PropTypes.string.isRequired,
  aboutHeading: PropTypes.string.isRequired,
  aboutSubheading: PropTypes.string.isRequired,
  aboutCopy: PropTypes.string.isRequired,
};

export const aboutBlockTypes = {
  aboutBlocks: PropTypes.array.isRequired,
};

export const aboutWhatBlockTypes = {
  whatBlocks: PropTypes.array.isRequired,
};

export const aboutWhyBlockTypes = {
  whyBlocks: PropTypes.array.isRequired,
};

export const aboutHowBlockTypes = {
  howBlocks: PropTypes.array.isRequired,
};

export const aboutWhoTypes = {
  team: PropTypes.array.isRequired,
};

export const homeClientBlockTypes = {
  clients: PropTypes.array.isRequired,
  partners: PropTypes.array.isRequired,
};

export const sectionHeadingTypes = {
  heading: PropTypes.string.isRequired,
  noUnderline: PropTypes.bool,
};

export const contactTypes = {
  heading: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  confirmationMessage: PropTypes.string.isRequired,
  adminEmail: PropTypes.string.isRequired,
};

export const categoryHeroTypes = {
  title: PropTypes.string.isRequired,
  smallTitle: PropTypes.string.isRequired,
  introText: PropTypes.string.isRequired,
};
export const aboutHeroTypes = {
  title: PropTypes.string.isRequired,
  smallTitle: PropTypes.string.isRequired,
  introText: PropTypes.string.isRequired,
};

export const categoryBlockTypes = {
  blocks: PropTypes.array.isRequired,
};

export const pageHeroTypes = {
  title: PropTypes.string.isRequired,
};

export const postCardTypes = {
  post: PropTypes.object.isRequired,
};

export const homePlatformTypes = {
  platformFeatures: PropTypes.array.isRequired,
};

export const homeServicesTypes = {
  services: PropTypes.array.isRequired,
};
