import { createGlobalStyle } from 'styled-components';
import { font, colors } from '../consts/style';

const Global = createGlobalStyle`

html { font-family: 'Open Sans', sans-serif; }

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  color: white;
  background: ${colors.dark};
}

h1 {
  ${font.h1};
}
h2 {
  ${font.h2};
}
h3 {
  ${font.h3};
}
h4 {
  ${font.h4};
}
p {
  ${font.p};
}

button:disabled,
button[disabled]{
  opacity: .6;
  cursor: not-allowed;
}

`;

export default Global;
