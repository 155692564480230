import React, { useContext } from 'react';

import ModalContext from '../store/modalContext';
import { ModalWrapper, ModalInner } from '../components/Elements';

export default function Confirmation() {
  const { data, closeModal } = useContext(ModalContext);
  return (
    <ModalWrapper onClick={closeModal}>
      <ModalInner>
        {(data && data.message) || 'Thanks!  We will be in touch shortly.'}
      </ModalInner>
    </ModalWrapper>
  );
}
