import styled from 'styled-components';

import { font, colors, z, bz } from '../consts/style';
import { fadeInUp } from '../style/animations';

export const Container = styled.div`
  padding: 0 2.4rem;
  width: 100%;
  max-width: 100%;
`;

export const Inner = styled.div`
  width: 120rem;
  max-width: 100%;
  margin: 0 auto;
`;

export const InnerMedium = styled.div`
  width: 104rem;
  max-width: 100%;
  margin: 0 auto;
`;

export const InnerSmall = styled.div`
  width: 82rem;
  max-width: 100%;
  margin: 0 auto;
`;

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  ${z.modalBackground};
`;

export const ModalInner = styled.div`
  background: black;
  padding: 1.2rem;
  position: relative;
  max-width: 100%;
  max-height: 100%;
  height: 20rem;
  width: 30rem;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  animation: ${fadeInUp} 0.3s;
  border: 0.4rem solid ${colors.blue};
  border-radius: 0.4rem;
  ${bz};
  ${font.h2};
  ${z.modal};
`;
