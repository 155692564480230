import React, { useContext } from 'react';
import styled from 'styled-components';

import ModalContext from '../store/modalContext';
import { ModalWrapper } from '../components/Elements';
import { colors, font } from '../consts/style';
import { fadeInUp } from '../style/animations';

const TeamModalInner = styled.div`
  background: white;
  width: 60rem;
  border-radius: 0.4rem;
  overflow: hidden;
  animation: ${fadeInUp} 0.3s;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  * {
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .header {
    background: ${colors.blue};
    padding: 1.2rem 2rem;
    position: relative;
    h3 {
      margin: 0;
      ${font.h3};
    }
    p {
      margin: 0;
    }
    button {
      position: absolute;
      top: 2.4rem;
      right: 2rem;
      width: 2.4rem;
      height: 2.4rem;
      ${font.h3};
      font-weight: bold;
      color: white;
      background: transparent;
      border: none;
      &:before {
        content: '';
        width: 0.4rem;
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        background: white;
      }
      &:after {
        content: '';
        width: 0.4rem;
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%) rotate(-45deg);
        background: white;
      }
    }
  }
  .body {
    max-height: 72vh;
    background: black;
    padding: 1.2rem 2rem;
    overflow: scroll;
  }
`;

export default function Team() {
  const { closeModal, data } = useContext(ModalContext);
  return (
    <ModalWrapper>
      <TeamModalInner>
        <div className="header">
          <h3>{data.name}</h3>
          <p>{data.title}</p>
          <button
            className="close"
            onClick={closeModal}
            title="Close modal"
            tabIndex={0}
          ></button>
        </div>
        <div className="body" dangerouslySetInnerHTML={{ __html: data.bio }} />
      </TeamModalInner>
    </ModalWrapper>
  );
}
