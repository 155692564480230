import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { graphql, useStaticQuery, Link } from 'gatsby';
import _map from 'lodash/map';

import { font } from '../consts/style';
import { Container, InnerMedium } from './Elements';
import mq from '../style/mq';

import FacebookIcon from '../images/facebook.svg';
import TwitterIcon from '../images/twitter.svg';
import InstagramIcon from '../images/instagram.svg';

export const Wrapper = styled.div`
  padding: 12rem 0 6rem;
  ${InnerMedium} {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .footer-col {
      width: 24%;
      margin-right: 1.3334%;

      &:nth-child(4n) {
        margin-right: 0;
      }

      ${mq.tablet`
        width: 49%;
        margin-right: 1%;
        margin-bottom: 2.4rem;

        &:nth-child(2n) {
          margin-right: 0;
        }
      `}

      ${mq.tabletSmall`
        width: 100%;
        margin-right: 0;
        margin-bottom: 2.4rem;

        &:nth-child(2n) {
          margin-right: 0;
        }
      `}

      h2 {
        ${font.h4blog};
      }

      &.links {
        display: flex;
        flex-direction: column;

        a {
          margin-bottom: 0.8rem;
        }
      }

      .icons {
        display: flex;
        a {
          margin-right: 1.2rem;
          img {
            width: 1.9rem;
          }
        }
      }
    }

    .bottom {
      width: 100%;
      text-align: center;
      margin-top: 2.4rem;
    }

    p,
    a {
      ${font.p};
      color: white;
    }
  }
`;

const Logo = styled(Img)`
  width: 12.7rem;
  max-width: 100%;
  margin-bottom: 1.2rem;
`;

const footerQuery = graphql`
  {
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    footer: datoCmsFooter {
      contactEmail
      hrEmail
      socialMedia {
        id
        platform
        link
      }
    }
  }
`;

const Footer = () => {
  const today = new Date();
  const year = today.getFullYear();
  const data = useStaticQuery(footerQuery);

  const iconMap = {
    facebook: FacebookIcon,
    twitter: TwitterIcon,
    instagram: InstagramIcon,
  };

  return (
    <Wrapper>
      <Container>
        <InnerMedium>
          <div className="footer-col">
            <div className="image">
              <Logo fluid={data.logo.childImageSharp.fluid} />
            </div>
            <div className="block">
              <h4>CONTACT</h4>
              <p>{data.footer.contactEmail}</p>
            </div>
          </div>
          <div className="footer-col">
            <h5>Follow us</h5>
            <div className="icons">
              {_map(data.footer.socialMedia, (icon) => (
                <a target="blank" key={icon.id} href={icon.link}>
                  <img
                    src={iconMap[icon.platform] || null}
                    alt={icon.platform}
                  />
                </a>
              ))}
            </div>
          </div>
          <div className="footer-col links">
            <Link title="About" to={'/about/'}>
              About
            </Link>
            <Link to="/blog/" title="Blog">
              Blog
            </Link>
            <Link to="/contact/" title="Contact">
              Contact
            </Link>
            <Link to="/services/" title="Services">
              Services
            </Link>
            <Link to="/industries/" title="Industries">
              Industries
            </Link>
          </div>
          <div className="footer-col">
            <div className="block">
              <h4>INTERESTED IN WRITING FOR COPYCAT?</h4>
              <p>{data.footer.hrEmail}</p>
            </div>
          </div>
          <div className="bottom">
            Copycat Copywriters &copy; 2017 - {year} &#8212;{' '}
            <Link to="/credits">Credits</Link>
          </div>
        </InnerMedium>
      </Container>
    </Wrapper>
  );
};

export default Footer;
