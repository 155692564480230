import React, { useState, Fragment, useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { graphql, useStaticQuery, Link } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import _chunk from 'lodash/chunk';
import _map from 'lodash/map';

import * as categoryTypes from '../types/categoryTypes';
import { colors, font, bz, z } from '../consts/style';
import { headerTypes } from '../types/propTypes';
import { Container, Inner } from './Elements';
import mq from '../style/mq';

const Wrapper = styled.nav`
  ${z.header};
  background: ${colors.dark};
  height: ${(props) => (props.scrolled === 'true' ? '6.4rem' : '8rem')};
  transition: height 0.4s;
  ${bz};
  width: 100%;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;

  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  * {
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  ${Container} {
    padding: 0;
    ${mq.tablet`
    padding: 0 2.4rem;
    `}
  }
  ${Inner} {
    width: 96rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${mq.tablet_up`
      width: 100%;
    `}
    ${mq.tablet`
      justify-content: flex-end;
    `}
  }
`;

const Image = styled(Img)`
  width: 16rem;
  margin: 0 4rem;

  ${mq.tablet`
      margin: 0;
  `}
`;

const ButtonWrapper = styled.div`
  width: 25%;
  display: flex;
  justify-content: flex-end;
  display: none;
  button {
    width: 3rem;
    height: 2rem;
    background: transparent;
    border: none;
    position: relative;
    div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 0.3rem;
      border-radius: 1rem;
      background: white;
      transition: 0.4s all ease;
      &:nth-child(2n) {
        top: 50%;
        transform: translateY(-50%);
      }
      &:nth-child(3n) {
        bottom: 0;
        top: unset;
      }
    }
  }
  &.open {
    button {
      div {
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        &:nth-child(2n) {
          opacity: 0;
        }
        &:nth-child(3n) {
          top: 50%;
          bottom: unset;
          transform: translateY(-50%) rotate(-45deg);
        }
      }
    }
  }

  ${mq.tablet`
    display: flex;
  `}
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  ${mq.tablet`
      width: 50%;
  `}
`;

const ContactWrapper = styled(Link).attrs({ to: '/contact/' })`
  background: ${colors.orange};
  width: 28rem;
  height: ${(props) => (props.scrolled === 'true' ? '6.4rem' : '8rem')};
  transition: height 0.4s;
  ${bz};

  button {
    height: 100%;
    width: 100%;
    background: transparent;
    border: none;
    ${font.menuLink};
    span {
      color: white;
    }
  }
  ${mq.tablet`
      display: none;
  `}
`;

const MenuLinksWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  a,
  button {
    margin-right: 3.2rem;
    color: white;
    ${font.menuLink};
    background: transparent;
    border: none;

    &.end {
      margin-right: 0;
    }

    &:hover {
      text-decoration: none;
      color: white;
    }
  }

  ${mq.tablet`
    display: none;
  `}
`;

const MobileMenu = styled.nav`
  transition: all 0.4s ease;
  position: fixed;
  top: ${(props) => (props.scrolled === 'true' ? '6.4rem' : '8rem')};
  height: ${(props) =>
    props.scrolled === 'true' ? 'calc(100% - 6.4rem)' : 'calc(100% - 8rem)'};
  transform: translateY(${(props) => (props.open ? '0%' : '-100%')});
  width: 100%;
  background: ${colors.dark};
  ${z.headerMobile};
  padding: 2.4rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: none;
  ${mq.tablet`
    display: flex;
  `}
  a,
  button {
    ${font.menuLink};
    font-size: 3.2rem;
    text-decoration: none;
    color: white;
    margin-bottom: 2.4rem;
    &:hover {
      text-decoration: none;
      color: white;
    }
    &.back {
      ${font.button};
      width: 20rem;
      max-height: 4rem;
    }
  }

  ${(props) =>
    !props.open &&
    `
    opacity: 0;
    `}
`;

const MobileSubNav = styled(MobileMenu)`
  ${z.headerMobileSub};
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: scroll;
  margin-top: 2.4rem;
  text-align: center;
  a {
    font-size: 2.4rem;
  }

  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  * {
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const MenuButton = styled.button`
  position: relative;
  &.active {
    color: ${colors.red};
  }
`;

const SubNav = styled.nav`
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  background: ${colors.dark};
  opacity: 0;
  pointer-events: none;
  display: flex;
  padding: 1.6rem;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  &.service {
    margin-left: 17rem;
  }
  &.platform {
    margin-left: -5rem;
  }
  &.industry {
    margin-left: 20rem;
  }

  ul {
    padding: 0;
    list-style: none;
    margin: 0;
    margin-right: 1.2rem;
    &:last-child {
      margin-right: 0;
    }

    li {
      margin-bottom: 1.2rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ${(props) =>
    props.shown &&
    `
    opacity: 1;
    pointer-events: all;
    `}
`;

const headerQuery = graphql`
  {
    logo: file(relativePath: { eq: "logo-cat.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    # DONT CHANGE THESE KEY NAMES (platform, service, etc)
    # THEY CORRELATE TO CATEGORY TYPES
    platform: allDatoCmsPlatformPage(sort: { fields: title, order: DESC }) {
      nodes {
        id
        title
        slug
      }
    }
  }
`;

export default function Header({ scrolled, scrollPosition, location }) {
  const data = useStaticQuery(headerQuery);
  const [open, setOpen] = useState(false);
  const [activeSubNav, setActiveSubNav] = useState(null);

  useEffect(() => {
    if (open && !activeSubNav) setOpen(false);
  }, [scrollPosition]);

  useEffect(() => {
    if (open) setOpen(false);
    if (activeSubNav) setActiveSubNav(null);
  }, [location]);

  function renderSubNav(type) {
    const shown = type === activeSubNav,
      subNavData = data[type] || {},
      navChunks = _chunk(subNavData.nodes, 5);

    if (navChunks.length === 0) return null;

    return (
      <SubNav shown={shown} className={type}>
        {_map(navChunks, (nc, i) => (
          <ul key={`${type}-nc-${i}`}>
            {_map(nc, (menuItem) => (
              <li key={menuItem.id}>
                <Link to={`/${type}/${menuItem.slug}/`}>{menuItem.title}</Link>
              </li>
            ))}
          </ul>
        ))}
      </SubNav>
    );
  }

  function renderMobileSubNav() {
    const subNavData = data[activeSubNav] ? data[activeSubNav].nodes : [];
    return (
      <MobileSubNav scrolled={scrolled.toString()} open={open}>
        <button className="back" onClick={() => setActiveSubNav(null)}>
          <span>Back</span>
        </button>
        {_map(subNavData, (item) => (
          <Link
            key={`mob-${item.id}`}
            title={item.title}
            to={`/${activeSubNav}/${item.slug}/`}
          >
            {item.title}
          </Link>
        ))}
      </MobileSubNav>
    );
  }

  function getClass(type) {
    return type === activeSubNav ? 'active' : '';
  }

  return (
    <Fragment>
      <OutsideClickHandler onOutsideClick={() => setActiveSubNav(null)}>
        <Wrapper scrolled={scrolled.toString()}>
          <Container>
            <Inner>
              <LogoWrapper>
                <Link title="Copycat Home" to="/">
                  <Image
                    backgroundColor={colors.dark}
                    fluid={data.logo.childImageSharp.fluid}
                  />
                </Link>
              </LogoWrapper>
              <MenuLinksWrapper>
                <Link title="About" to={'/about/'}>
                  About
                </Link>
                <MenuButton
                  className={getClass(categoryTypes.PLATFORM)}
                  onClick={() =>
                    setActiveSubNav(
                      activeSubNav === categoryTypes.PLATFORM
                        ? null
                        : categoryTypes.PLATFORM
                    )
                  }
                >
                  <span>Platform</span>
                </MenuButton>
                {renderSubNav(categoryTypes.PLATFORM)}
                <Link title="Blog" to={'/apply/'}>
                  Apply
                </Link>
                <Link lassName="end" title="Blog" to={'/blog/'}>
                  Blog
                </Link>
              </MenuLinksWrapper>
              <ContactWrapper scrolled={scrolled.toString()}>
                <button>
                  <span>Contact Us</span>
                </button>
              </ContactWrapper>
              <ButtonWrapper className={open ? 'open' : ''}>
                <button onClick={() => setOpen(!open)}>
                  <div />
                  <div />
                  <div />
                </button>
              </ButtonWrapper>
            </Inner>
          </Container>
        </Wrapper>
        <MobileMenu scrolled={scrolled.toString()} open={open}>
          <Link title="About" to={'/about/'}>
            About
          </Link>
          <MenuButton
            className={getClass(categoryTypes.PLATFORM)}
            onClick={() => setActiveSubNav(categoryTypes.PLATFORM)}
          >
            <span>Platform</span>
          </MenuButton>
          <Link title="Contact" to={'/contact/'}>
            Contact
          </Link>
          <Link title="Contact" to={'/apply/'}>
            Apply
          </Link>
          <Link title="Blog" to={'/blog/'}>
            Blog
          </Link>
        </MobileMenu>{' '}
        {activeSubNav && renderMobileSubNav(activeSubNav)}
      </OutsideClickHandler>
    </Fragment>
  );
}

Header.propTypes = headerTypes;
