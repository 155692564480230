// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-apply-js": () => import("./../../../src/pages/apply.js" /* webpackChunkName: "component---src-pages-apply-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-credits-js": () => import("./../../../src/pages/credits.js" /* webpackChunkName: "component---src-pages-credits-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-js": () => import("./../../../src/pages/industries.js" /* webpackChunkName: "component---src-pages-industries-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-industry-page-js": () => import("./../../../src/templates/IndustryPage.js" /* webpackChunkName: "component---src-templates-industry-page-js" */),
  "component---src-templates-platform-page-js": () => import("./../../../src/templates/PlatformPage.js" /* webpackChunkName: "component---src-templates-platform-page-js" */),
  "component---src-templates-service-page-js": () => import("./../../../src/templates/ServicePage.js" /* webpackChunkName: "component---src-templates-service-page-js" */)
}

