import React, { useContext } from 'react';
import styled from 'styled-components';
import useWindowScrollPosition from '@rehooks/window-scroll-position';
import Helmet from 'react-helmet';

import Header from '../components/Header';
import Footer from '../components/Footer';
import Reboot from '../style/reboot';
import Global from '../style/global';
import ModalContext from '../store/modalContext';
import Modals from '../modals';
import { colors } from '../consts/style';
import { layoutTypes } from '../types/propTypes';

const SiteWrapper = styled.div`
  background: ${colors.dark};
  padding-top: 8rem;
`;

const Layout = ({ children, location }) => {
  const { open } = useContext(ModalContext);
  const win = typeof window !== 'undefined' ? useWindowScrollPosition() : {};
  const scrolled = win.y >= 30;

  function renderModal() {
    if (!open) return null;
    const ModalComponent = Modals[open];
    return <ModalComponent />;
  }
  return (
    <SiteWrapper>
      <Helmet>
        <link rel="preconnect" href="https://datocms-assets.com" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Montserrat:wght@400;500;700&family=Open+Sans:wght@400;600;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Reboot />
      <Global />
      <Header
        location={location}
        scrolled={scrolled}
        scrollPosition={win ? win.y : null}
      />
      {children}
      {renderModal()}
      <Footer />
    </SiteWrapper>
  );
};

Layout.propTypes = layoutTypes;

export default Layout;
